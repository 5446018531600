import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar></Navbar>
          <div className="content">
            <Switch>

              <Route exact path="/">
                <Home/>
              </Route>

              <Route exact path="/Contact">
                <Contact/>
              </Route>

            </Switch>

          </div>
          <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
