import {Link} from 'react-router-dom';
import Selfie from './resources/Images/HS.jpg';
import LULogo from './lu-logo.png';
import LUShield from './lu-shield.svg'
import LoretoLogo from './loreto-logo.png';
import StmLogo from './STM_Logo.png';
import CodeImg from './Code_Img.png';
import DissertationImg from './DissertationImg.png';
import { useEffect, useRef, useState } from 'react';

const Home = () => {
        //CREDIT: https://onesheep.org/insights/animate-on-scroll-with-tailwind-css

        const stmRef = useRef(null);
        const loretoRef = useRef(null);
        const lancasterRef = useRef(null);

        const qualReferences = [stmRef,loretoRef,lancasterRef];

        const codePicRef= useRef(null);
        const languagesRef= useRef(null);

        const skillsReferences = [languagesRef];

        const dissertationPic = useRef(null);
        const dissertationDesc = useRef(null);

        const dissertationReferences = [dissertationDesc, dissertationPic];

        const [skillsAnimation, toggleAnimation] = useState(false);

        // Callback for IntersectionObserver
        const qualCallback = function(entries) {
            entries.forEach(entry => {
                // Is the element in the viewport?
                //console.log("In View");
                if (entry.isIntersecting) {
                    // Add the fadeIn class:
                    entry.target.classList.add("animate-fade-in-down");
                    entry.target.classList.remove("opacity-0");
                    // entry.target.classList.add("opacity-1");
                    console.log("TEST");
                   
                }
                
                
        });
        };

        
        const skillsCallback = function(entries) {
            entries.forEach(entry => {
                // Is the element in the viewport?
                //console.log("In View");
                if (entry.isIntersecting) {
                    // Add the fadeIn class:
                    entry.target.classList.add("animate-fade-in-down");
                    entry.target.classList.remove("opacity-0");
                    toggleAnimation(true);
                    // entry.target.classList.add("opacity-1");
                    console.log("TEST");

                }
                
                
        });
        };

              
        const dissCallback = function(entries) {
            entries.forEach(entry => {
                // Is the element in the viewport?
                //console.log("In View");
                if (entry.isIntersecting) {
                    // Add the fadeIn class:
                    entry.target.classList.add("animate-fade-in-down");
                    entry.target.classList.remove("opacity-0");
                    toggleAnimation(true);
                    // entry.target.classList.add("opacity-1");
                    console.log("TEST");

                }
                
                
        });
        };


        let options = {
            root:null,
            rootMargin: "0px",
            threshold: 0.8
        }
    

        useEffect(()=>{
                
                // Set up a new observer
                const qualObserver = new IntersectionObserver(qualCallback,options);

                qualReferences.forEach( function(ref){
                    qualObserver.observe(ref.current);
                    }
                )

                const skillsObserver = new IntersectionObserver(skillsCallback,options);

                skillsReferences.forEach( function(ref){
                    skillsObserver.observe(ref.current);
                }
                )

                const dissertationObserver = new IntersectionObserver(dissCallback,options);

                dissertationReferences.forEach( function(ref){
                    dissertationObserver.observe(ref.current);
                }

                )


            },[qualReferences,skillsReferences,options]


        )


    return (  

        <div className="home font-Raleway text-center grid grid-cols-1 lg:grid-cols-12 lg:grid-gap-5  max-w-full">
            <div className="grid grid-cols-1 row-start-1 row-end-1  lg:col-start-1 lg:col-end-6 xl:col-start-1 xl:col-end-6 2xl:col-start-2 2xl:col-end-7  my-2 ">
                    <div><p className="animate-fade-in-down-slow text-xxl md:text-2xxl 2xl:text-3xxl 3xl:text-4xxl col-start-1 col-end-2 lg:row-start-1 lg:row-end-2">Hi.</p></div>
                    <div className="animate-fade-in-down-slow col-start-1 col-end-2 lg:row-start-2 row-end-3 lg:row-end-4 xl:text-lg 2xl:text-xl 3xl:text-4xl" ><p> &#128075; Hi my name is Hemanshu Solanki, I am interested in both front-end and back-end development. I also have an interest in Artificial Intelligence. </p></div>
            </div>
            <div className="animate-fade-in-down-slow lg:col-start-6 xl:col-start-6 xl:col-end-13 2xl:col-start-7 2xl:col-end-12 3xl:col-end-12 lg:col-end-13 px-4 row-start-2 lg:row-start-1 row-end-2 lg:row-end-1 md:m-2 xl:mt-10"><img className="object-cover 2xl:object-cover m-auto shadow-xl rounded-md h-auto align-middle border-none max-h-75 my-2 " src={Selfie} alt="Selfie of Hemanshu"/></div>
            
            
            <div className="row-start-3 row-end-3 lg:row-start-2 lg:row-end-2 lg:col-start-1 lg:col-end-13 ">
                <h1 className="pageTitle text-4xl md:text-6xl lg:text-9xl text-center py-16 px-5">My Qualifications</h1>
            </div>
            {/* <div className="grid grid-cols-10 row-start-3 row-end-5 col-start-1 col-end-4 m-10">
                    <div className="col-start-1 col-end-5 row-start-3 row-end-5"><img className="object-cover m-auto  rounded-md h-auto align-middle border-none max-h-75 my-2" src={LULogo} alt=""></img></div>
                    <div className="col-start-5 col-end-11 row-start-3 row-end-4"><p className="text-center  text-6xl m-auto ">Lancaster University</p></div>
                    <div className="col-start-5 col-end-11 row-start-4 row-end-5"><p className="text-center text-5xl m-auto font-thin">First Class, Computer Science BSc</p></div>
            </div> */}

            <div className="grid grid-cols-1 row-start-4 row-end-4 lg:grid-cols-9 lg:row-start-3 lg:row-end-3 lg:col-start-1 lg:col-end-13 2xl:col-start-2 2xl:col-end-12 py-8 lg:py-16">

                    <div ref={stmRef} className=" opacity-0  col-start-1 col-end-1 row-start-4 row-end-5 lg:col-start-1 lg:col-end-4 lg:row-start-5 lg:row-end-7 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-7xl mx-3 2xl:mx-15" >
                        <div className="col-start-1 col-end-1 row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-2 max-w-lg" src={StmLogo} alt="St Thomas More logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>St Thomas More R.C. College</p></div>
                        <div className="col-start-1 col-end-1 row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>GCSE: 10 A*-B</p></div>
                    </div>

                    <div ref={loretoRef} className="js-show-on-scroll opacity-0 col-start-1 col-end-1 row-start-5 row-end-6 lg:col-start-4 lg:col-end-7 lg:row-start-5 lg:row-end-7 grid-rows-2 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-7xl mx-3 2xl:mx-15" >
                        <div className="col-start-1 col-end-1 row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 max-w-lg p-2 my-2 " src={LoretoLogo} alt="Loreto College logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>Loreto College</p></div>
                        <div className="col-start-1 col-end-1 row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>A-Levels: 3 A*-B</p></div>
                    </div>
                    

                    <div ref={lancasterRef} className="js-show-on-scroll opacity-0 col-start-1 col-end-1 row-start-6 row-end-7 lg:col-start-7 lg:col-end-10 lg:row-start-5 lg:row-end-7 grid-rows-2 rounded overflow-hidden shadow-lg max-h-80 max-w-full xl:max-w-7xl lg:max-w-lg mx-3 2xl:mx-15">

                        <div className="col-start-1 col-end-1 row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 max-w-lg p-2 my-2 " src={LUShield} alt="Lancaster University logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>Lancaster University</p></div>
                        <div className="col-start-1 col-end-1 row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl 3xl:text-3xl"><p>First Class, Computer Science BSc</p></div>
                    </div>

            </div>

            <div className="  row-start-5 row-end-5 lg:row-start-4 lg:row-end-4 col-start-1 col-end-2 lg:col-start-1 lg:col-end-13 grid grid-cols-1 lg:grid-cols-12">
                <div className="row-start-1 row-end-1 lg:col-start-1 lg:col-end-13 ">
                    <div><h1 className=" pageTitle text-5xl md:text-6xl lg:text-9xl text-center py-10 px-5">Skills</h1></div>
                </div>
                {/* <div ref={codePicRef} className="opacity-0 lg:col-start-1 lg:col-end-7 xl:col-start-1 xl:col-end-7 2xl:col-start-2 2xl:col-end-7 3xl:col-end-8  px-4 row-start-2 row-end-3 md:m-2 "><img className="object-cover 2xl:object-cover m-auto shadow-xl rounded-md h-auto align-middle border-none max-h-75 my-2 " src={CodeImg} alt="Code Image"/></div> */}
                <div ref={languagesRef}  className="opacity-0 bg-opacity-100 row-start-3 row-end-4 lg:row-start-2 lg:row-end-3 col-start-1 col-end-1 lg:col-start-1 lg: col-end-13 flex flex-col justify-center">
                    <div className="text-3xl md:text-4xl lg:text-5xl 3xl:text-8xl ">
                        {skillsAnimation &&
                        <ul>
                             
                            <li className="animate-fade-in-down">Java</li>
                            <li className="animate-fade-in-down-2">JS</li>
                            <li className="animate-fade-in-down-3">React</li>
                            <li className="animate-fade-in-down-4">Tailwind</li>
                            <li className="animate-fade-in-down-5 ">GraphQL</li>
                        </ul>
                        }
                    </div>
                </div>
            </div>

            <div className="row-start-6 row-end-6 lg:row-start-5 lg:row-end-5 col-start-1 col-end-2 lg:col-start-1 lg:col-end-13">
                <h1 className="pageTitle text-4xl md:text-6xl lg:text-9xl text-center py-10 px-5">My Dissertation</h1>
            </div>

            <div ref={dissertationDesc} className="opacity-0 row-start-7 row-end-7 lg:row-start-6 lg:row-end-6 col-start-1 col-end-2 lg:col-start-2 lg:col-end-6 flex flex-col justify-center">
                <p className="text-center lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl ">For my dissertation, I created an unsupervised segmentation method that can be used on Covid-19 CT scans. This was a development on the xDNN approach which was made by my supervisor Dr. Plamen Angelov and his PhD student Mr. Eduardo Soares. Their paper can be found <a className="underline" target="_blank" href="https://arxiv.org/pdf/1912.02523.pdf">here.</a></p>
            </div>

            <div ref={dissertationPic} className="opacity-0 row-start-8 row-end-8 lg:row-start-6 lg:row-end-6 col-start-1 col-end-2 lg:col-start-7 lg:col-end-12 ">
                <img className="object-cover 2xl:object-cover m-auto shadow-xl rounded-2xl h-auto align-middle border-none max-h-96 lg:max-h-full my-2 " src={DissertationImg} alt="Dissertation Image"/>
            </div>

        </div>

       
       
    );
}
 
export default Home;