const Footer = () => {
    return ( 
        <nav className="text-center">
            <h2 className="py-5 xl:text-lg 2xl:text-2xl"> &copy; Hemanshu Solanki</h2>
            <div className="footerLinks"></div>

        </nav>

     );
}
 
export default Footer;