import {Link} from 'react-router-dom';
import logo from './HSLogo.png';

const Navbar = () => {
    return (  
        //   shadow-lg 
        <div className="sticky top-0 z-10 ">
            <nav className="animate-fade-in-down navbar bg-bianca-600 max-w-full mx-auto grid lg:grid-cols-9 md:grid-cols-7 grid-cols-1 py-1 tracking-tight">
                {/* <div className="animate-fade-in-down rounded-full col-start-1 col-end-2 px-4 max-h-14" >
                    <Link to="/"><p className="m-auto h-auto align-middle border-none max-h-14 text-5xl font-Raleway text-logoColor-900 ">HS</p>
                    </Link> 
                </div> */}
                <div className="text-center md:text-left lg:text-center col-start-1 col-end-2 row-start-1 row-end-2 md:row-start-1 md:row-end-2 lg:col-start-4 lg:col-end-7 md:col-start-1 md:col-end-5 "><Link to="/"><h1 className=" animate-fade-in-down font-Allison m-auto inline-block p-2 mx-2 dark:text-white text-logoColor-900 text-6xl 2xl:text-8xl 3xl:text-9xl">Hemanshu Solanki</h1></Link></div>
         
                <div className="animate-fade-in-down links font-Raleway m-auto row-start-2 row-end-3 col-start-1 col-end-2 lg:col-start-8 lg:col-end-10 md:col-start-5 md:col-end-8 md:row-start-1 md:row-end-2 flex lg:flex-nowrap justify-center md:justify-end text-xl text-center space-x-1 lg:space-x-4 mx-1 lg:mx-4 ">
                    <Link to="/"><div className="bg-transparent hover:text-bianca-100 rounded-lg px-2 lg:px-4 xl:text-lg 2xl:text-2xl 3xl:text-4xl">Home</div></Link> 
                    {/* <Link  to="/"><div className ="bg-transparent hover:text-bianca-100 rounded-lg px-2 lg:px-4">Blog</div></Link> */}
                    <Link  to="/Contact"><div className ="bg-transparent hover:text-bianca-100 rounded-lg px-2 lg:px-4 xl:text-lg 2xl:text-2xl 3xl:text-4xl">Contact</div></Link>
                    
                </div>



            </nav>

           
        </div>
    );
}
 
export default Navbar;