
import instaLogo from './instagram_Logo.png';
import facebookLogo from './facebook_logo.png';
import gmailLogo from './Gmail_Logo.png';
import twitterLogo from './twitter_logo.png';
import snapchatLogo from './snapchatLogo.jpeg';
import linkedInLogo from './LinkedinLogo.png';
import { Link } from 'react-router-dom';
const Contact = () => {

    function openLink(id){
        switch (id) {
            case 1:
                window.open("https://www.instagram.com/hemanz_/");
                break;
            case 2:
                window.open("https://www.facebook.com/people/Hemanshu-Solanki/1644234150/");
                break;
            case 3:
                window.open(window.location.href = "mailto:hemanzsolanki@gmail.com?subject=&body=");
                break;
            case 4:
                window.open("https://twitter.com/hemanz21");
                break;
            case 5:
                window.open("https://www.linkedin.com/in/hemanshusolanki/");
                break;
            default:
                break;
        }
    
    }

    return ( 
     
            <div className="grid grid-cols-2 lg:grid-cols-10 my-10">
                {/* <div className="animate-fade-in col-start-1 col-end-2 row-start-1 row-end-2 lg:col-start-1 lg:col-end-3 grid grid-cols-1 md:grid-cols-2 rounded overflow-hidden shadow-lg max-h-50 max-w-full lg:max-w-lg mx-3">
                        <img className="object-cover row-start-1 row-end-2 col-start-1 col-end-2 m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={instaLogo} alt=""/>
                        <div className="row-start-2 row-end-3 col-start-1 col-end-2 md:row-start-1 md:row-end-2 md:col-start-2 md:col-end-3 p-2 flex flex-col justify-center"> <div><p className="text-center">Instagram</p></div></div>
                        <div className="row-start-2 row-end-3 p-2"><p className="text-center">@Hemanz_</p></div>

                        <div className="row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={instaLogo} alt=""/></div>
                        <div className="row-start-2 row-end-3 p-2"><p className="text-center">Instagram</p></div>
                        <div className="row-start-3 row-end-4 p-2"><p className="text-center">@Hemanz_</p></div>

                </div> */}

                <div onClick={()=>openLink(1)} className="animate-fade-in cursor-pointer col-start-1 col-end-3 row-start-1 row-end-2 md:col-end-2 lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-2 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-4xl mx-3">
                        <div className="row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={instaLogo} alt="Instagram Logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl text-black hover:text-gray-200"><p className="text-center">Instagram</p></div>
                        <div className="row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">@Hemanz_</p></div>
                </div>
             

                <div onClick={()=>openLink(2)} className="animate-fade-in cursor-pointer col-start-1 col-end-3 row-start-2 row-end-3 md:col-start-2 md:col-end-3 md:row-start-1 md:row-end-2 lg:col-start-3 lg:col-end-5 lg:row-start-1 lg:row-end-2 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-4xl mx-3">
                        <div className="row-start-1 row-end-2"><img className="object-cover m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={facebookLogo} alt="Facebook logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">Facebook</p></div>
                        <div className="row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">Hemanshu Solanki</p></div>
                </div>

                <div onClick={()=>openLink(3)} className="animate-fade-in cursor-pointer col-start-1 col-end-3 row-start-3 row-end-4 md:row-start-2 md:row-end-3 md:col-end-2 lg:col-start-5 lg:col-end-7 lg:row-start-1 lg:row-end-2 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-4xl mx-3">
                        <div className="row-start-1 row-end-2"><img className="object-cover  m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={gmailLogo} alt="Gmail logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">Email</p></div>
                        <div className="row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">hemanzsolanki@gmail.com</p></div>
                </div>

                <div onClick={()=>openLink(4)} className="animate-fade-in cursor-pointer col-start-1 col-end-3 row-start-4 row-end-5 md:col-start-2 md:col-end-3 md:row-start-2 md:row-end-3 lg:col-start-7 lg:col-end-9 lg:row-start-1 lg:row-end-2 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-4xl mx-3">
                        <div className="row-start-1 row-end-2"><img className="object-cover rounded-full m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={twitterLogo} alt="Twitter logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">Twitter</p></div>
                        <div className="row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">@hemanz</p></div>
                </div>

                <div onClick={()=>openLink(5)} className="animate-fade-in cursor-pointer col-start-1 col-end-3 row-start-5 row-end-6 md:row-start-3 md:col-end-2 md:row-end-4 lg:col-start-9 lg:col-end-11 lg:row-start-1 lg:row-end-2 grid-rows-3 rounded overflow-hidden shadow-lg max-h-80 max-w-full lg:max-w-lg xl:max-w-4xl mx-3">
                        <div className="row-start-1 row-end-2"><img className="object-cover rounded-b-sm m-auto rounded-md h-auto align-middle border-none max-h-40 my-2 p-5 max-w-40" src={linkedInLogo} alt="LinkedIn logo"/></div>
                        <div className="row-start-2 row-end-3 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">LinkedIn</p></div>
                        <div className="row-start-3 row-end-4 p-2 xl:text-lg 2xl:text-2xl"><p className="text-center">Hemanshu Solanki</p></div>

                </div>

          

            </div>

      

        
           
     
     );
}
 
export default Contact;